<template>
    <a-card :border="false">
        <div class="realtor">
            <!-- 查询表单 -->
            <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
                <a-row :gutter="8">
                    <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                        <a-form-model-item label="小区名称">
                            <a-input v-model="searchData.name" placeholder="小区名称" allowClear></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :xl="6" :xxl="4" :sm="12">
                      <a-form-model-item label="小区地址">
                        <PCD
                          ref="pcd"
                          placeholder="请选择区域"
                          :province.sync="searchData.province"
                          :city.sync="searchData.city"
                          :district.sync="searchData.area"
                          :provinceId.sync="searchData.provinceId"
                          :cityId.sync="searchData.cityId"
                          :districtId.sync="searchData.areaId"
                        >
                        </PCD>
                      </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                      <a-form-model-item label="状态">
                        <DictSelect
                          field="authStatus"
                          :value.sync="searchData.authStatus"
                          style="width: 100%"
                          placeholder="请选择状态"
                        ></DictSelect>
                      </a-form-model-item>
                    </a-col>
                    <a-col :xxl="4" :xl="5" :md="8" sm="2">
                        <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div>
                <a-button type="primary" v-if="isAdd" @click="toHandler('add')">新增</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length == 1 && isEdit" type="primary" @click="toHandler('edit')">修改</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length == 1 && isDelete" type="danger" @click="toHandler('del')">删除</a-button>
              <a-button
                v-if="isAudit && selectedRowKeys.length > 0 && selectedRows[0].authStatus == 2 && selectedRows[0].taskId != null"
                type="primary"
                @click="toHandlerAll"
              >批量审核</a-button
              >
              <a-upload
                name="file"
                :action="uploadUrl"
                :headers="headers"
                :showUploadList="false"
                @change="uploadChange"
              >
                <a-button icon="upload" type="default">导入</a-button>
              </a-upload>
              <a-button type="default" icon="download"  @click="downLoadTemp">
                <slot>下载导入模版</slot>
              </a-button>
            </div>

            <!-- 数据表格 -->
            <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
                :columns="columns" :data-source="tableData" bordered :pagination="page"
                @change="changeTable" :customRow="changeTableRow">
                <span slot="imageUrl" slot-scope="item">
                    <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
                </span>
                <span slot="province" slot-scope="item, row">
                   {{row.province}}{{row.city}}{{row.area}}{{row.address}}
                </span>
                <span slot="authStatus" slot-scope="text">
                    <a-tag v-if="text == 1" color="#333333">未处理</a-tag>
                    <a-tag v-if="text == 2" color="#e6a23c">待审核</a-tag>
                    <a-tag v-if="text == 3" color="#108ee9">审核通过</a-tag>
                    <a-tag v-if="text == 4" color="#67c23a">审核拒绝</a-tag>
                  </span>
            </a-table>
            <!-- 编辑表单 -->
            <DealerStore-edit-modal ref="DealerStoreEditModal" @reload="getDataReload"></DealerStore-edit-modal>
            <SelectStaffModal ref="SelectStaffModal"></SelectStaffModal>
            <StaffAddModal ref="StaffAddModal"></StaffAddModal>

          <a-modal
            v-model="visibleAll"
            title="批量政策审核"
            @ok="handleOkAll"
            @cancel="handleCanceAll"
            :maskClosable="false"
            width="40%"
          >
            <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
              <a-form-model-item label="是否通过">
                <a-radio-group v-model="statusAll">
                  <a-radio :value="4"> 通过 </a-radio>
                  <a-radio :value="3"> 拒绝 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="备注">
                <a-textarea v-model="rejectReason"></a-textarea>
              </a-form-model-item>
            </a-form-model>
          </a-modal>
        </div>
    </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerStoreEditModal from './components/DealerStoreEditModal.vue'
import { delDealerStore, listDealerStore, selectByIdDealerStore } from './api/DealerStoreApi'
import SelectStaffModal from './components/SelectStaffModal'
import StaffAddModal from './components/StaffAddModal.vue'
import { checkPermission } from '@/utils/permissions'
import {baseURL} from '@/utils/request';
import {auditTaskBatch} from '@/api/activiti';
import {COMMUNITY_TEMP} from '@/utils/downloadTempHref';

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'dealerStore',
    components: {
        DealerStoreEditModal,
        SelectStaffModal,
        StaffAddModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: checkPermission('community:management:add'),
            isEdit: checkPermission('community:management:edit'),
            isDelete: checkPermission('community:management:delete'),
            isAudit: checkPermission('community:management:audit'),
            statusAll: 4,
            rejectReason: '',
            visibleAll: false,

            //上传文件地址
            uploadUrl: baseURL + '/api/firm/community/excelReaderNewTemp',
            //上传文件请求头
            headers: {
              'authorization-firm': this.$store.getters.token,
            },
            downloadTempHref: COMMUNITY_TEMP,
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: false,
                // type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
      downLoadTemp (){
        window.open(this.downloadTempHref, '_blank')
      },
      // 批量审核弹框
      toHandlerAll() {
        let everyResult = this.selectedRows.every((item) => {
          return item.authStatus === 2 && item.taskId != null
        })
        if (everyResult) {
          this.visibleAll = true
          this.dataList = this.selectedRows
        } else {
          this.$notification.error({ message: '请选择相同待审核记录' })
        }
      },
      handleOkAll() {
        const _this = this
        let result = 2
        if (_this.statusAll == 3) {
          result = 3
        }
        auditTaskBatch({ taskIds: _this.dataList.map((item) => item.taskId), result: result ,comment: _this.rejectReason }).then((res) => {
          if (res.code == 200) {
            _this.$message.success('审核成功')
            _this.getData()
          }else {
            _this.$message.error(res.message)
          }
          _this.visibleAll = false
        })
      },
      handleCanceAll() {
        this.visibleAll = false
        this.statusAll = ''
        this.rejectReason = ''
      },
      //导入
      uploadChange (info) {
        this.tableLoading = true
        setTimeout(()=>{
          if(info.file.response){
            let hint = info.file.response
            if(hint.code === 200) {
              this.tableLoading = false
              this.$message.success(hint.message)
              this.getData()
            }else if(hint.code === 500) {
              this.tableLoading = false
              this.$message.error(hint.message)
            }
            if (info.file.status !== 'uploading') {
              console.log(info.file, info.fileList)
            }
          }
        })

      },
        getDataReload(){
           this.getData()
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            this.selectedRowKeys = []
            this.selectedRows = []
            listDealerStore ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page.current = 1;
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {};
            this.page.current = 1;
            this.page.pageSize = 10;
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.DealerStoreEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.DealerStoreEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delDealerStore(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                _this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
                case 'select_add':
                    _this.$refs.SelectStaffModal.setRowData(_this.selectedRowKeys[0], 'select_add')
                    break
                case 'select_check':
                    _this.$refs.SelectStaffModal.setRowData(_this.selectedRowKeys[0], 'select_check')
                    break
                case 'add_staff':
                    _this.$refs.StaffAddModal.isShow(_this.selectedRows[0], 'add_staff')
                    break
            }
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
</style>
